import React from "react";
import { Menu } from "@headlessui/react";
import { Navigate } from "ellipse/navigate/Navigate";
import Logo from "./../../shared/Logo";
import { useIndexState } from "./../Index";
import { IServerIdentityManager } from "./../../services/IServerIdentityManagerInterop";
import { useErrorState } from "ellipse/error/Error";

export interface IndexNavbarProps extends React.HTMLAttributes<HTMLDivElement> {}

const IndexNavbar: React.FC<IndexNavbarProps> = (props: IndexNavbarProps) => {

	const { hasDemoVideo } = useIndexState();
	const { setErrorResult } = useErrorState();

	const doSignin = () => {
		const server = new IServerIdentityManager();
		const request = new IServerIdentityManager.IdentityLoginRequest();
		request.identityProvider = IServerIdentityManager.IdentityProvider.AWS_COGNITO;
		request.redirect = "projects.html";
		request.redirectFailure = "index.html";
		server.login(request).then((result: IServerIdentityManager.IdentityLoginResult) => {
			if (!result.success) {
				setErrorResult(result);
			}
			if (result.success && result.OAuthRedirect) {
				Navigate.navigateTo(result.OAuthRedirect);
			}
		});
	};

	const doSignup = () => {
		const server = new IServerIdentityManager();
		const request = new IServerIdentityManager.IdentitySignupRequest();
		request.identityProvider = IServerIdentityManager.IdentityProvider.AWS_COGNITO;
		request.redirect = "projects.html";
		request.redirectFailure = "index.html";
		server.signup(request).then((result: IServerIdentityManager.IdentitySignupResult) => {
			if (!result.success) {
				setErrorResult(result);
			}
			if (result.success && result.OAuthRedirect) {
				Navigate.navigateTo(result.OAuthRedirect);
			}
		});
	};

	return (
		<div id="indexnavbar-navbar-root" className="w-full flex justify-center">
			<div id="indexnavbar-navbar-mid" className="container mx-auto">
				<div id="indexnavbar-navbar-container" className="bg-gray-800 p-4 pt-12 pb-8 flex justify-between items-center">
					<div id="indexnavbar-left-section" className="flex items-center space-x-2">
						<Logo />
					</div>
					<div id="indexnavbar-mid-section" className="flex items-center space-x-6">
						<Menu as="span" id="indexnavbar-overview" className="text-gray-300 cursor-pointer" onClick={doSignup}>
							Sign Up
						</Menu>
						<Menu as="span" id="indexnavbar-overview" className="text-gray-300 cursor-pointer">
							<a href="faq.html">FAQ</a>
						</Menu>
						{hasDemoVideo && (
							<Menu as="span" id="indexnavbar-overview" className="text-gray-300 cursor-pointer">
								Demo Video
							</Menu>
						)}
						<Menu as="span" id="indexnavbar-search-feature" className="text-gray-300 cursor-pointer">
							<a href="pricing.html">Pricing</a>
						</Menu>
					</div>
					<div id="indexnavbar-right-section">
						<div id="indexnavbar-right-sectioncontainer" className="w-24 flex justify-end">
							<button id="indexnavbar-sign-in-button" className="hover:!font-bold text-white rounded-full pl-4 pr-4 px-3 py-1" onClick={doSignin}>
								Sign in
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

};

export default IndexNavbar;