import React from "react";
import { Button } from "@headlessui/react";
import { FaFolder } from "react-icons/fa";
import { useIndexState } from "./../Index";
import { Navigate } from "ellipse/navigate/Navigate";
import { IServerIdentityManager } from "./../../services/IServerIdentityManagerInterop";
import { useErrorState } from "ellipse/error/Error";

export interface IndexHtmlConversionProps extends React.HTMLAttributes<HTMLDivElement> {}

const IndexHtmlConversion: React.FC<IndexHtmlConversionProps> = (props: IndexHtmlConversionProps) => {

	const { hasDemoVideo, hasFigmaSupport, prerelease } = useIndexState();
	const { setErrorResult } = useErrorState();

	const doSignup = () => {
		const server = new IServerIdentityManager();
		const request = new IServerIdentityManager.IdentitySignupRequest();
		request.identityProvider = IServerIdentityManager.IdentityProvider.AWS_COGNITO;
		request.redirect = "projects.html";
		request.redirectFailure = "index.html";
		server.signup(request).then((result: IServerIdentityManager.IdentitySignupResult) => {
			if (!result.success) {
				setErrorResult(result);
			}
			if (result.success && result.OAuthRedirect) {
				Navigate.navigateTo(result.OAuthRedirect);
			}
		});
	};

	return (
		<div id="indexhtmlconversion-main-container" className="flex items-center justify-between p-16 bg-gray-800 rounded-3xl mt-8">
			<div id="indexhtmlconversion-text-container" className="w-2/5 space-y-4 pt-16 pb-16 pr-16">
				<h1 id="indexhtmlconversion-main-heading" className="text-4xl font-bold text-white">
					<span>AI-assisted conversion</span>
					<br />
					<span>for {hasFigmaSupport ? "SVG layouts" : "Uizard layouts"}</span>
				</h1>
				<p id="indexhtmlconversion-description">Use our tools and GPT-4o to generate the components you want from {hasFigmaSupport ? "Figma and " : ""} Uizard layouts</p>
				<div id="indexhtmlconversion-button-group" className="md:space-x-4 space-y-4">
					{!prerelease && (
						<div>
							<Button id="indexhtmlconversion-explore-button" className="hover:!font-bold rounded-full pl-4 pr-4 px-3 py-1" onClick={doSignup}>
								Try now
							</Button>
							<div id="indexhtmlconversion-trynow" className="mt-4">
								No credit card required, 5 free conversions included
							</div>
						</div>
					)}
					{prerelease && (
						<>
							<span id="indexhtmlconversion-main-comingsoon" className="text-2xl font-bold text-white">
								Coming soon
							</span>
							<Button
								id="indexhtmlconversion-explore-button"
								className="hover:!font-bold rounded-full pl-4 pr-4 px-3 py-1"
								onClick={() => {
									Navigate.navigateTo("https://forms.office.com/r/u7v26F1VS2");
								}}
							>
								Join Waitlist
							</Button>
						</>
					)}
					{hasDemoVideo && (
						<Button id="indexhtmlconversion-demo-button" className="hover:!font-bold rounded-full pl-4 pr-4 px-3 py-1">
							See demo
						</Button>
					)}
				</div>
			</div>
			<div id="indexhtmlconversion-folder-icon" className="w-3/5 flex justify-center">
				<img src="images/hero.png" />
			</div>
		</div>
	);

};

export default IndexHtmlConversion;